*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  font-size: 62.5%;
  font-family: Geist, Lato, "Be Vietnam", Helvetica, sans-serif;
  overflow-x: hidden;
}

@media only screen and (max-width: 37.5em) {
  html,
  body {
    font-size: 55%;
  }
}

.ant-btn-primary {
  box-shadow: none;
}
